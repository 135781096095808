<template>
<div>
    <div class=" row produtos pl-5 pb-2 pt-2" v-if="listLabelProdutos[0]">
      <template v-for="(v, k) in listLabelProdutos">
        <v-col cols="6" sm="4" md="3" :key="k" class="mt-0 mb-0 pt-0 pb-0">
          <v-checkbox :disabled="notSelect && !filtroProdutos.includes(v)"  v-model="filtroProdutos" :label="v" :value="v"  hide-details class="text-checkbox" />
        </v-col>
      </template>
    </div>

    <div  v-else class="d-block justify-content-center text-center mt-2 mb-2">
       Carregando produtos, Aguarde !! <v-progress-circular class="ml-2"  indeterminate color="primary" />
    </div>

  <div style="max-with: 150px; min-with: 150px" class="d-flex justify-content-center">
    <div id="chart"></div>
  </div>
    <!-- <graficoModalidades style="max-with: 300px; min-with: 300px" v-if="filtroProdutos[0]" :produtos="filtroProdutos" :dados="listProdutos" /> -->
</div>
</template>

<script>
import ApexCharts from 'apexcharts'
import { mapGetters } from 'vuex';
// eslint-disable-next-line
import { map, size, filter, groupBy, orderBy } from 'lodash'
import { currencyEqual } from '@/core/filters/currency'
import moment from 'moment';
moment.locale('pt-br')
const namespaceStore = 'organizzeRelatoriosVendasProdutos'
export default {
  name: 'testeGrafico',
  props:['formatLabel'],
  components: {
    // graficoModalidades: () => import('./graficoModalidades')
  },
  data () {
    return {
      count: 0,
      element: null,
      notSelect: false,
      filtroProdutos: [],
      options:{
        theme: {
          mode: 'dark', 
          monochrome: {
            enabled: false
          }
        },
        // 0-linha 1-barra negativa  2-barra positiva
        colors:[
          '#00c6f2','#00d5a4', '#a2ef85', // primeiro selecionado
          '#ffc89c', '#f5b045', '#00a390', // segundo
          '#c4c07d', '#f2e0a2', '#e2e2e2'
        ],
        noData: {
          text: 'Carregando...'
        },
        series: [],
        fill: {
          type: "solid",
        },
        chart: {
          height: 500,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0, 0, 0, 10, 2, 2],
          width: [2, 4, 4, 2, 4, 4],
        },
        dataLabels:{
          style: {
            // colors: ['#F44336', '#E91E63']
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '10%',
            distributed: false
          }
        },
        grid: {
          padding: {
            left: 20,
            right: 20
          },
          row: {
            // colors:['#008ffb','#ffde61', '#d7263d', '#47ca82'],
          },
          column: {
            // colors:['#008ffb','#ffde61', '#d7263d', '#47ca82'],
          }
        },
        markers: {
          size: 4,
          radius: 2,
           colors:[
            '#00c6f2','#00d5a4', '#a2ef85', // primeiro selecionado
            '#ffc89c', '#f5b045', '#00a390', // segundo
            '#c4c07d', '#f2e0a2', '#e2e2e2'
          ],
          hover: {
            size: 10,
            sizeOffset: 3
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return String(currencyEqual(value || 0)).split(',')[0]
            }
          }
        },
        xaxis: {
          categories: [],
          label: {
            formatter: (v) => {
              return v
            }
          }
        },
        tooltip: {
          intersect: true,
          custom: ({ seriesIndex, dataPointIndex, w }) => {
            let html = ''
            // se for grafico de coluna
            if (w.globals.seriesNames[seriesIndex].indexOf('Custeio') !== -1 || w.globals.seriesNames[seriesIndex].indexOf('Vendas') !== -1) {
              let title = w.globals.seriesNames[seriesIndex].split('-')
              html += '<div style="width: 300px; class="pt-2 pb-0" style="color:white; text-aling: center; border-radios: 10px; background: #000">'  
              // html += ` ${w.globals.seriesNames[seriesIndex]} :${currencyEqual(w.globals.seriesTotals[seriesIndex])}`

                html += `${title[1]}`
              html += '</div>'
              html +=`<div class="d-block text-center" style="background: #4F4F4F"> ${title[0]}: ${currencyEqual(w.globals.initialSeries[seriesIndex].data[dataPointIndex])} </div>`
              return html
            }

            const filtroModalidades = arguments[0].getModalidades(w.globals.seriesNames[seriesIndex], w.globals.categoryLabels[dataPointIndex])
            if(!size(filtroModalidades)) return ''

            html += '<div class="pt-1 pb-0" style="color:white; text-aling: center; border-radios: 10px; background: #000">'
              // data atual html += w.globals.initialSeries[dataPointIndex]
               html += w.globals.seriesNames[seriesIndex]
              
            html += '</div>'

            html += '<div style="width: 300px; text-aling: left; padding-left:10px; padding-rigth:10px">'
            let custeio = 0
            let count = 1
            let total = 0
            let dados = map(filtroModalidades, v => {
              if (v[0]) v[0].valueFloat = parseFloat(v[0].valor_jogo)
              return v[0]
            })

            const order = orderBy(dados, ['valueFloat'], ['desc'])
            const ultimo = size(order)

            for(let it of order) {
              if (count === 0) html += '<hr/>'
              total += parseFloat(it.valor_jogo)
              
              custeio += (parseFloat(it.premios || 0) + parseFloat(it.comissao))

              html += `<span class="d-block text-center mt-1 mb-1"> ${it.name_modality} :  ${it ? currencyEqual(it.valor_jogo) : 0 } </span>`

              if (ultimo !== count) html += '<hr/>'

              count++
            }
            html +='</div>'
            
            // valor total
            html +=`<div class="d-block text-center" style="background: #0fab5c"> Vendas: ${currencyEqual(total || 0)} </div>`
            html +=`<div class="d-block text-center" style="background: #4F4F4F"> Custeio: ${currencyEqual(custeio * (-1) || 0)} </div>`
            html +=`<div class="d-block text-center" style="background: #000"> Resultado: ${currencyEqual(total + (custeio * -1) || 0)} </div>`
            return html
          },
          shared: false,
          y: {
            formatter: (v) => {
              return currencyEqual(v) // valor dentro do tolltip
            }
          }
        }
      }
    }
  },
  mounted () {
    const vm = this
    vm.element = new ApexCharts(document.querySelector("#chart"), vm.options)
    vm.element.render()
    vm.updateSeries()
    vm.$nextTick(() => vm.filtroProdutos = ['BILHETE_TRADICIONAL'])

  },
  watch: {
    'listProdutos' (val) {
      this.$nextTick(() => { this.updateSeries(val) })
      this.count++
    },
    filtroProdutos (v) {
      const vm = this
      this.notSelect = false
      if (size(v) === 2) this.notSelect = true
      this.$nextTick(() => { vm.updateSeries([1]) })
      
    }
  },
  computed: {
    ...mapGetters(namespaceStore, ['listProdutos', 'listLabelProdutos']),
    formatData () {
      // eslint-disable-next-line
      let count = this.count // bug nao atualiza se nao trocar o label      
      let dados = [{
          name: 'Dados',
          type: 'line',
          data: []
        }]

      const result = this.listProdutos
      if (size(result) === 0) return dados

      // separando produtos
      const filtroSelectProduto = filter(result, v => this.filtroProdutos.indexOf(v.produto) !== -1)

      // series de datas
      const labels = map(groupBy(filtroSelectProduto, it => {
        if (this.formatLabel === 'week') return `${moment().weeks(it.data + 1).startOf('week').format('DD/MM')}`
        if (this.formatLabel === 'month') return moment().month(it.data - 1).format('MMMM/YYYY')
        return `${it.data}`
        }), (v, k) => k)

      // eslint-disable-next-line
      const qtdFiltros = map(this.filtroProdutos, v => {
        
        const produto = filtroSelectProduto.filter(it => it.produto === v)

        const agrupaDia = groupBy(produto, it => `${it.data}`)
        
        // calculo de resultado descontando comissao e premios
        let valores = map(agrupaDia, (it, key) => {
          return {
            valor: map(it, v => parseFloat(v.valor_jogo) + ((parseFloat(v.comissao || 0) + parseFloat(v.premios || 0)) * -1)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
            data: key
          }
        })

        console.log(valores)

        // calculo de custeio
        let custeios = map(agrupaDia, (it, key) => {
          return {
            custeio: map(it, v => parseFloat(v.premios || 0) + parseFloat(v.comissao || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) * -1,
            data: key
          }
        })
        console.log(custeios)

        /* Valores de prêmios */
        let totalPremios = map(agrupaDia, (it, key) => {
          return {
            premios: map(it, v => v.valor_jogo || 0).reduce((a, b) => parseFloat(a) + parseFloat(b)),
            data: key
          }
        })

        // tem que deixar igual qtd de series com qtd de labels para tolltip nao bugar!!
        if (size(valores) === size(labels)) {
          return {
            valores: map(valores, it => it.valor), 
            custeios: map(custeios, it => it.custeio ),
            totalPremios: map(totalPremios, it => it.premios)
          }
        }
        
        valores = map(labels, v => {
          const existeLabel = filter(valores, it => v === it.data)[0]
          if (existeLabel) return existeLabel.valor
          return 0
        })

        custeios = map(labels, v => {
          const existeLabel = filter(custeios, it => v === it.data)[0]
          if (existeLabel) return existeLabel.custeio
          return 0
        })

        totalPremios = map(labels, v => {
          const existeLabel = filter(totalPremios, it => v === it.data)[0]
          if (existeLabel) return existeLabel.premios
          return 0
        })

        return {valores, custeios, totalPremios }
      })  

      // gerando series
      const series = {
        xaxis: {
          categories:labels
        },
        series: map(this.filtroProdutos, (v, k) => {
          let itens = [
            {
              name: v,
              type: 'line',
              data: qtdFiltros[k].valores,
            },
            {
              name: `Custeio - ${v}`,
              type: 'column',
              data: qtdFiltros[k].custeios,
            },
            {
              name: `Vendas - ${v}`,
              type: 'column',
              data: qtdFiltros[k].totalPremios,
            }
          ]
          return itens
        })
      }
      
      // each(this.filtroProdutos, (v, k) => {
      //   series.series.push({
      //     name: `Custeio - ${v}`,
      //     type: 'column',
      //     data: qtdFiltros[k].custeios,
      //   })
      //   series.series.push({
      //     name: `Prêmios - ${v}`,
      //     type: 'column',
      //     data: qtdFiltros[k].totalPremios,
      //   })
      // })
      series.series = series.series.flat()
      return series
    }
  },  
  methods: {
    updateSeries (val) {
      if (size(val) === 0) {
         this.element.updateOptions({
           series: [],
           noData: {
             text: 'Não existe dados!'
           }
         })
        return
      }

      this.element.updateOptions({
      series: this.formatData.series,
      xaxis: {
        categories: this.formatData.xaxis.categories
      }
      })
    },
    getModalidades (v, dia) {
      const atual = v
      const produtos = Object.assign({}, this.listProdutos)
      const filtroProdutos = filter(produtos, v => v.produto === atual)
    
      // agrupa por dia e produto
      const agrupaDiaProd = groupBy(filtroProdutos, it => {
        if (this.formatLabel === 'week') return `${moment().weeks(it.data + 1).startOf('week').format('DD/MM')}#${atual}`
        if (this.formatLabel === 'month') return `${moment().month(it.data - 1).format('MMMM/YYYY')}#${atual}`
        return `${it.data}#${atual}`
      })
      // agrupa por MODALIDADE BASEADO NO PRODUTO
      const agrupaModalides = groupBy(agrupaDiaProd[`${dia}#${atual}`], it => it.name_modality)
      return agrupaModalides
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input{
  margin: 0
}
#chart {
  text-align: center;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.modalidades {
  position: relative;
  background: #555;
  width: 150px;
  height: 150px;

}

</style>